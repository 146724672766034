<template>
  <div class="my-6">
    <FerretSection />
    <NavMenuSecondary  />
  </div>
</template>

<script>
import FerretSection from "../components/newpetrecord/ferret/FerretSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    FerretSection,
    NavMenuSecondary
  },
};
</script>